var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('b',{staticClass:"h1"},[_vm._v("Basic information")])]),_c('b-card-body',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"*Avatar:","label-cols-md":"3"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"max-width":"200px"}},[_c('b-aspect',{attrs:{"aspect":"1:1"}},[_c('b-img-lazy',{staticStyle:{"position":"absolute","max-width":"200px"},attrs:{"src":_vm.model.avatar,"fluid":"","thumbnail":"","rounded":""}}),_c('div',{staticClass:"fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"},[_c('b-form-file',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center img-box",attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.previewFiles}}),_c('b-button',{staticClass:"btn-icon rounded-circle btn-add",attrs:{"variant":"outline-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Title:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Title"},on:{"input":function($event){return _vm.generateSlug(_vm.model.name)}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Path:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Path","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Path"},model:{value:(_vm.model.path),callback:function ($$v) {_vm.$set(_vm.model, "path", $$v)},expression:"model.path"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Api:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Api","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Api"},model:{value:(_vm.model.api_id),callback:function ($$v) {_vm.$set(_vm.model, "api_id", $$v)},expression:"model.api_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Component category:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Component category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.categoryOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.categoryOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.component_category_id),callback:function ($$v) {_vm.$set(_vm.model, "component_category_id", $$v)},expression:"model.component_category_id"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Component Group:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Component Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.groupOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.groupOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.component_group_id),callback:function ($$v) {_vm.$set(_vm.model, "component_group_id", $$v)},expression:"model.component_group_id"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Position:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Position"},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Is active:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Is active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","reduce":function (x) { return x.value; },"options":_vm.activeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Type:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","reduce":function (x) { return x.value; },"options":_vm.typeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Param api:","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"placeholder":"Param api"},model:{value:(_vm.model.param_api),callback:function ($$v) {_vm.$set(_vm.model, "param_api", $$v)},expression:"model.param_api"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Setting:","label-cols-md":"3"}},[_c('b-form-textarea',{attrs:{"placeholder":"Setting"},model:{value:(_vm.model.setting),callback:function ($$v) {_vm.$set(_vm.model, "setting", $$v)},expression:"model.setting"}})],1)],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }